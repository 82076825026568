
import { Vue, Component } from 'vue-property-decorator';

import $app from '@/plugins/modules';
import { Common } from '@/common';
import { System } from '@/modules/system';
import { Clinic } from '@/modules/clinic'
import { IResearch, IResearchFilter, IResearches } from '../types';
import { ITableHeader, IPagination } from '../../system/types';

@Component
export default class ResearchesView extends Vue {
  common = $app.module(Common);
  system = $app.module(System);
  clinic = $app.module(Clinic);

  loading = false;

  filter: IResearchFilter = $app.clone(this.clinic.$store.Researches.filter);

  showItem = false;
  processItem = false;
  selectedItem: IResearch | null = null;

  get headers(): Array<ITableHeader> {
    return [
      {
        text: $app.i18n('dplus.customers.PatientName'),
        value: 'PatientName',
        align: 'left',
        width: '25%'
      },
      {
        text: $app.i18n('system.Name'),
        value: 'LabName',
        align: 'left',
        width: '20%'
      },
      {
        text: $app.i18n('dplus.customers.Edit.PatientPhone'),
        value: 'PatientPhone',
        align: 'left',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.labs.ResearchId'),
        value: 'ResearchId',
        align: 'center',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.labs.ResearchSended'),
        value: 'ResearchSended',
        align: 'left',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.labs.ResearchDueDate'),
        value: 'ResearchDueDate',
        align: 'left',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.labs.ResearchPrice'),
        value: 'ResearchPrice',
        align: 'right',
        width: '10%'
      },
      {
        text: '',
        value: 'ResearchState',
        align: 'center',
        width: '5%'
      }
    ];
  }

  get search(): IResearches {
    return this.clinic.$store.Researches;
  }

  async findNext(pagination: IPagination) {
    if (!this.loading) {
      this.loading = true;
      await this.clinic.$store.findResearch(pagination);
      this.loading = false;
    }
  }

  async findFirst() {
    this.loading = false;
    const request = this.system.$module.newSearch(this.clinic.$store.Researches, this.filter);
    this.clinic.$store.setResearches({
      items: [],
      filter: request
    });
    this.findNext(request);
  }

  async store(item: IResearch) {
    this.processItem = true;
    try {
      this.selectedItem = await this.clinic.$store.storeResearch(item);
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async remove(item: IResearch) {
    this.processItem = true;
    try {
      await this.clinic.$store.removeResearch(item);
      this.showItem = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async changeState(state: string) {
    this.processItem = true;
    if (this.selectedItem) {
      try {
        const item: IResearch = $app.clone(this.selectedItem);
        item.state = state;
        await this.clinic.$store.setResearchState(item);
        this.selectedItem = item;
      } catch (err) {
        $app.pushError(err);
      }
    }
    this.processItem = false;
  }

  async open(item: IResearch) {
    this.selectedItem = $app.clone(item);
    this.showItem = true;
  }

  append() {
    this.selectedItem = {
      id: null,
      laboratory: null,
      patient: null,
      sended: null,
      dueDate: null,
      received: null,
      details: '',
      price: 0,
      state: 'N',
      color: '',
      doctor: null,
      patientName: '',
      patientPhone: '',
      patientPhoto: null,
      labName: ''
    };
    this.showItem = true;
  }

  mounted() {
    if (this.search.filter.totals === 0) {
      this.findFirst();
    }
  }
}
